@font-face {
  font-family: 'HK Grotesk';
  src: local('HK Grotesk Light'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Light.woff');

  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.eot');
  src: local('HK Grotesk SemiBold Italic'), local('HKGrotesk-SemiBoldItalic'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.eot');
  src: local('HK Grotesk Bold Italic'), local('HKGrotesk-BoldItalic'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-Bold.eot');
  src: local('HK Grotesk Bold'), local('HKGrotesk-Bold'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Bold.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Bold.woff') format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-Medium.eot');
  src: local('HK Grotesk Medium'), local('HKGrotesk-Medium'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Medium.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Medium.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.eot');
  src: local('HK Grotesk ExtraBold'), local('HKGrotesk-ExtraBold'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.ttf')
      format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.eot');
  src: local('HK Grotesk SemiBold'), local('HKGrotesk-SemiBold'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-Italic.eot');
  src: local('HK Grotesk Italic'), local('HKGrotesk-Italic'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Italic.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Italic.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Italic.ttf')
      format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.eot');
  src: local('HK Grotesk Light Italic'), local('HKGrotesk-LightItalic'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-Light.eot');
  src: local('HK Grotesk Light'), local('HKGrotesk-Light'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Light.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Light.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-Regular.eot');
  src: local('HK Grotesk Regular'), local('HKGrotesk-Regular'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Regular.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Regular.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-Black.eot');
  src: local('HK Grotesk Black'), local('HKGrotesk-Black'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Black.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Black.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.eot');
  src: local('HK Grotesk Medium Italic'), local('HKGrotesk-MediumItalic'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.woff2')
      format('woff2'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.woff')
      format('woff'),
    url('../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.ttf')
      format('truetype');
  font-weight: 500;
  font-style: italic;
}
