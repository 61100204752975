@import './typography';

html,
body {
  font-family: HK Grotesk, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

*{
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background:#f5f5f5;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background:  #879DF2 ;
  }
}